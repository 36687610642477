/* eslint-disable react/prop-types */
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { BeatLoader } from 'react-spinners';

import './style.css';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { editDeviceApiCall } from '../../../services/users';

export default function EditDeviceModal({
  accessLevel,
  show,
  setShow,
  handleClose,
  device,
  handleDeviceUpdated,
}) {
  const adminGroups = useSelector((state) => state.admin.groups) || [];
  if (adminGroups.length) {
    const find = adminGroups.find((obj) => obj.GroupID === '-1');
    if (!find) {
      adminGroups.unshift({ GroupID: '-1', GroupName: 'Select an Option' });
    }
  }
  const [IMEINumber, setIMEINumber] = useState('');
  const [Group_Association, setGroupAssociation] = useState('');
  const [Lat, setLat] = useState('');
  const [Real_Name, setReal_Name] = useState('');
  const [Status, setStatus] = useState('');
  const [ShipmentDate, SetShipmentDate] = useState();
  const [Long, setLong] = useState('');
  const [loading, setLoading] = useState(false);
  const [FCIUniqueSerialNumber, setFCIUniqueSerialNumber] = useState('');

  const today = dayjs();
  function validateForm() {
    return Group_Association && Group_Association !== 'Select an Option' && IMEINumber.length > 0;
  }
  useEffect(() => {
    if (device && device.IndexID) {
      setIMEINumber(device.IMEINumber);
      if (device.Group_Association) {
        setGroupAssociation(device.Group_Association);
      }
      setLat(device.Lat);
      setReal_Name(device.Real_Name);
      setStatus(device.Status);
      if (device.ShipmentDate) {
        SetShipmentDate(dayjs(device.ShipmentDate));
      } else {
        SetShipmentDate();
      }
      setLong(device.Long);
      setFCIUniqueSerialNumber(device.FCIUniqueSerialNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device]);

  function validateLatLong() {
    // Latitude validation
    if (Lat) {
      if (!(parseFloat(Lat) <= 90 && parseFloat(Lat) >= -90)) {
        return 'Invalid Latitude';
      }
    }
    // Longitude validation
    if (Long) {
      if (!(Long <= 180 && Long >= -180)) {
        return 'Invalid Longitude';
      }
    }
    return '';
  }

  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    let latLongError = validateLatLong();
    if (latLongError != '') {
      toast.error(latLongError);
      setLoading(false);
    } else {
      const deviceObj = {
        IMEINumber,
        Group_Association: Group_Association == -1 ? null : Group_Association,
        Status: Status,
        ShipmentDate: ShipmentDate ? ShipmentDate.format('YYYY-MM-DD') : null,
        ...(Lat && { Lat: parseFloat(Lat) }),
        ...(Long && { Long: parseFloat(Long) }),
        Real_Name,
        ...(FCIUniqueSerialNumber && FCIUniqueSerialNumber.length && { FCIUniqueSerialNumber }),
      };

      editDeviceApiCall(deviceObj, device.IndexID)
        .then((res) => {
          toast.success('Successfully edited the device!');
          setLoading(false);
        })
        .catch((error) => {
          toast.error(
            error.toString().includes('409')
              ? 'IMEI Number & FCI Unique Serial Number must be unique'
              : 'Error occured',
          );
          setLoading(false);
        });
    }
  }

  function handleCloseOrHide(event) {
    setIMEINumber('');
    setReal_Name('');
    setFCIUniqueSerialNumber('');
    setLat('');
    setLong('');
    handleDeviceUpdated();
  }

  return (
    <>
      <Modal show={show} onHide={handleCloseOrHide}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Device</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextField
            autoFocus
            className="full-width mb-3"
            label="IMEI Number"
            value={IMEINumber}
            onChange={(e) => setIMEINumber(e.target.value)}
            disabled={accessLevel !== 1}
          />
          <TextField
            className="full-width mb-3"
            label="Real Name"
            value={Real_Name}
            onChange={(e) => setReal_Name(e.target.value)}
          />
          <TextField
            select
            className="full-width mb-3"
            label="Status"
            value={Status}
            onChange={(e) => {
              setStatus(e.target.value);
              if (e.target.value !== 'Shipped') {
                SetShipmentDate();
              }
            }}
            disabled={accessLevel !== 1}
          >
            <MenuItem value={'Inactive'}>Inactive</MenuItem>
            <MenuItem value={'Testing'}>Testing</MenuItem>
            <MenuItem value={'Shipped'}>Shipped</MenuItem>
          </TextField>
          {Status === 'Shipped' ? (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div className="shipment-date-div mb-3">
                <DatePicker
                  value={ShipmentDate}
                  onChange={(newValue) => {
                    SetShipmentDate(newValue);
                  }}
                  maxDate={today}
                  disabled={accessLevel !== 1}
                  label="Shipment Date"
                />
              </div>
            </LocalizationProvider>
          ) : (
            <></>
          )}
          <TextField
            select
            className="full-width mb-3"
            label="Group Association"
            value={Group_Association}
            disabled={accessLevel !== 1}
            onChange={(e) => setGroupAssociation(e.target.value)}
          >
            {adminGroups.map((adminGroup) => (
              <MenuItem key={adminGroup.GroupID} value={adminGroup.GroupID}>
                {adminGroup.GroupName}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            className="full-width mb-3"
            label="Latitude"
            value={Lat}
            onChange={(e) => setLat(e.target.value)}
          />
          <TextField
            className="full-width mb-3"
            label="Longitude"
            value={Long}
            onChange={(e) => setLong(e.target.value)}
          />
          <TextField
            className="full-width mb-3"
            label="FCI Unique Serial Number"
            value={FCIUniqueSerialNumber}
            onChange={(e) => setFCIUniqueSerialNumber(e.target.value)}
            disabled={accessLevel !== 1}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button color="secondary" variant="contained" onClick={handleCloseOrHide}>
            Close
          </Button>
          <Button
            size="medium"
            variant="contained"
            className="float-right"
            disabled={!validateForm()}
            onClick={handleSubmit}
          >
            {loading && <BeatLoader size={10} color="#fff" speedMultiplier={0.7} />}
            {!loading && 'Update'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
