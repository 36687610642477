import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { HashLoader } from 'react-spinners';
import { Link } from 'react-router-dom';

import logo from './logo.png';
import './style.css';
import { loginAction } from '../../actions/login';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    const credentials = {
      email,
      password,
    };
    dispatch(loginAction(credentials, setLoading));
  }

  return (
    <div className="limiter">
      <ToastContainer position="top-right" />
      <div className="container-login100">
        <div className="wrap-login100">
          <div>
            <img src={logo} alt="Logo" className="Center Logo" />
            <h3 className="Center Margin mb-4">Sign In</h3>
            <div className="login-fields-container">
              <TextField
                id="outlined-password-input"
                label="Email Address"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="login-fields"
              />
              <TextField
                id="outlined-password-input"
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="login-fields"
              />
            </div>
            <Button
              size="medium"
              type="submit"
              variant="contained"
              className="full-width"
              disabled={!validateForm() || loading}
              onClick={handleSubmit}
            >
              {loading && <HashLoader size={30} color="#1b6bae" speedMultiplier={0.8} />}
              {!loading && 'Login'}
            </Button>
            <div className="mt-2 auth-nav-link">
              <Link to="/forgot-password">Forgot password?</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
