/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDatalogById } from '../../actions/devices';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
// import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import { convertColumnToRealName } from '../../lib/convertColumnsToRealLanguage';
const MyExportCSV = (props) => {
  const handleClick = () => {
    props.onExport();
  };
  return (
    <div>
      <button className="btn btn-success" onClick={handleClick}>
        Export to CSV
      </button>
    </div>
  );
};
export default function ViewDevicesDataLogById(props) {
  const devicesDataLogArr = useSelector((state) => state.devices.datalogById);
  const [columns, setColumns] = useState();
  const [data, setData] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    const search = props.location.search;
    const params = new URLSearchParams(search);
    const id = params.get('id');

    dispatch(getDatalogById(id));
  }, [dispatch, props.location.search]);
  useEffect(() => {
    if (devicesDataLogArr?.length) {
      const tempColumns = [];
      const objectKeys = Object.keys(devicesDataLogArr[0]);
      objectKeys &&
        objectKeys.map((key) => {
          if (key != 'GeoCoords'){
            tempColumns.push({
              dataField: convertColumnToRealName(key),
              text: convertColumnToRealName(key),
            });
          }
        });
      const tempData = [];
      devicesDataLogArr.map((data) => {
        const tempObj = {};
        objectKeys &&
          objectKeys.map((key) => {
            tempObj[convertColumnToRealName(key)] = data[key];
          });
        tempData.push(tempObj);
      });
      setColumns(tempColumns);
      setData(tempData);
    } else {
      setData([]);
    }
  }, [devicesDataLogArr]);

  return (
    <div style={{ overflowX: 'auto' }}>
      {data?.length && (
        <ToolkitProvider keyField="id" data={data} columns={columns} exportCSV>
          {(props) => (
            <div>
              <MyExportCSV {...props.csvProps} />
              <hr />
              <BootstrapTable {...props.baseProps} pagination={paginationFactory()} />
            </div>
          )}
        </ToolkitProvider>
      )}
    </div>
  );
}
