import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { RiAdminFill } from 'react-icons/ri';
import { AiFillContacts, AiFillHome, AiFillMessage, AiFillWarning } from 'react-icons/ai';
import { FaBook } from 'react-icons/fa6';
import { FcElectricalSensor } from 'react-icons/fc';

import { setUserAfterLogin } from '../../../actions/login';
import { toggleAdminSubMenu } from '../../../actions/ui';
import logo from '../../Auth/logo.png';
import './style.scss';
import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';

const CustomSidebar = () => {
  const dispatch = useDispatch();
  const showAdminSubMenu = useSelector((state) => state.ui.showAdminSubMenu);
  const globalState = useSelector((state) => state);
  const user = globalState.user.user;
  const accessLevel = user.AccessLevel;
  const [collapsedSidebar, setCollapsedSidebar] = useState(false);

  useEffect(() => {
    dispatch(setUserAfterLogin());
  }, [dispatch]);

  const hideShowChild = () => {
    dispatch(toggleAdminSubMenu());
  };

  return (
    <Sidebar id="sidebar-wrapper" collapsed={collapsedSidebar}>
      <img
        src={logo}
        alt="Logo"
        className="Center Logo"
        style={{
          marginTop: collapsedSidebar ? '20px' : '10px',
          height: collapsedSidebar ? '30px' : '70px',
          width: 'auto',
        }}
      />
      <hr />
      <div
        className={collapsedSidebar ? 'sidebar-expanded' : 'sidebar-collapse'}
        onClick={() => setCollapsedSidebar(!collapsedSidebar)}
      >
        <div id="toggle-button">
          {collapsedSidebar ? <FaAngleDoubleRight size={25} /> : <FaAngleDoubleLeft size={25} />}
        </div>
      </div>
      <hr />

      <Menu
        menuItemStyles={{
          button: ({ level, active, disabled }) => {
            // only apply styles on first level elements of the tree
            if (level === 0)
              return {
                backgroundColor: active ? '#dae0e5 !important' : undefined,
                color: '#495057',
                '&:hover': {
                  backgroundColor: '#dae0e5 !important',
                  border: '1px solid rgba(0,0,0,.125)',
                },
              };
            if (level === 1)
              return {
                marginLeft: '1.25rem',
                color: '#495057',
              };
            return {
              color: '#495057',
              padding: '.75rem 1.25rem',
              border: '1px solid rgba(0,0,0,0)',
            };
          },
        }}
        id="sidebar-menu"
        renderExpandIcon={({ open }) => {
          return <i id="icon" className={open ? 'fa fa-caret-down' : 'fa fa-caret-right'}></i>;
        }}
        transitionDuration={600}
        closeOnClick={false}
      >
        <MenuItem
          component={<Link to="/" />}
          active={window.location.pathname === '/'}
          icon={<AiFillHome />}
        >
          Dashboard
        </MenuItem>
        <MenuItem
          component={<Link to="/critical-data" />}
          active={window.location.pathname === '/critical-data'}
          icon={<AiFillWarning />}
        >
          Critical Event Log
        </MenuItem>
        <MenuItem
          component={<Link to="/data-log" />}
          active={window.location.pathname === '/data-log'}
          icon={<FaBook />}
        >
          Data Log
        </MenuItem>
        <MenuItem
          component={<Link to="/devices" />}
          active={window.location.pathname === '/devices'}
          icon={<FcElectricalSensor />}
        >
          View Devices
        </MenuItem>
        {(accessLevel === 1 || accessLevel === 2) && (
          <MenuItem
            component={<Link to="/add-email-telephone" />}
            active={window.location.pathname === '/add-email-telephone'}
            icon={<AiFillContacts />}
          >
            Add Email/Telephone
          </MenuItem>
        )}
        {accessLevel === 1 && (
          <MenuItem
            component={<Link to="/admin/add-message-template" />}
            active={window.location.pathname === '/admin/add-message-template'}
            icon={<AiFillMessage />}
          >
            Add Message Template
          </MenuItem>
        )}
        {(accessLevel === 1 || accessLevel === 2) && (
          <SubMenu
            label="Admin"
            active={[
              '/admin/add-user',
              '/admin/view-edit-users',
              '/admin/add-group',
              '/admin/view-edit-groups',
              '/admin/add-device',
              '/admin/view-edit-devices',
              '/admin/add-ms-provider',
              '/admin/view-edit-ms-provider',
            ].includes(window.location.pathname)}
            icon={<RiAdminFill />}
            open={showAdminSubMenu}
            onOpenChange={hideShowChild}
          >
            {accessLevel === 1 && (
              <>
                <MenuItem
                  active={window.location.pathname === '/admin/add-user'}
                  component={<Link to="/admin/add-user" />}
                >
                  Add User
                </MenuItem>
                <MenuItem
                  active={window.location.pathname === '/admin/view-edit-users'}
                  component={<Link to="/admin/view-edit-users" />}
                >
                  View/Edit User
                </MenuItem>
                <MenuItem
                  active={window.location.pathname === '/admin/add-group'}
                  component={<Link to="/admin/add-group" />}
                >
                  Add Group
                </MenuItem>
                <MenuItem
                  active={window.location.pathname === '/admin/view-edit-groups'}
                  component={<Link to="/admin/view-edit-groups" />}
                >
                  View/Edit Group
                </MenuItem>
                <MenuItem
                  active={window.location.pathname === '/admin/add-device'}
                  component={<Link to="/admin/add-device" />}
                >
                  Add Device
                </MenuItem>
                <MenuItem
                  active={window.location.pathname === '/admin/view-edit-devices'}
                  component={<Link to="/admin/view-edit-devices" />}
                >
                  View/Edit Device
                </MenuItem>
                <MenuItem
                  active={window.location.pathname === '/admin/add-ms-provider'}
                  component={<Link to="/admin/add-ms-provider" />}
                >
                  Add MultiSpeak provider
                </MenuItem>
                <MenuItem
                  active={window.location.pathname === '/admin/view-edit-ms-provider'}
                  component={<Link to="/admin/view-edit-ms-provider" />}
                >
                  View/Edit MultiSpeak provider
                </MenuItem>
              </>
            )}
            {accessLevel === 2 && (
              <MenuItem
                active={window.location.pathname === '/admin/view-edit-devices'}
                component={<Link to="/admin/view-edit-devices" />}
              >
                View/Edit Device
              </MenuItem>
            )}
          </SubMenu>
        )}
      </Menu>
    </Sidebar>
  );
};

export default CustomSidebar;
