/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { getDataLogCol } from '../../actions/devices';
import { getDeviceDatalogApiCall } from '../../services/devices';
import DownloadCSVHeaderRow from '../Utils/DownloadCSVHeaderRow';

export default function ViewDevicesDataLog() {
  const datalogCol = useSelector((state) => state.devices.datalogCol);
  const [downloading, setDownloading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!datalogCol?.length) {
      dispatch(getDataLogCol());
    }
  }, [datalogCol?.length, dispatch]);

  const onDownloadClick = async (from, to) => {
    setDownloading(true);
    const res = await getDeviceDatalogApuCall(0, 0, false, from, to);
    setDownloading(false);
    return res.data.map((d) => {
      delete d['GeoCoords'];
      return d;
    });
  };

  return (
    <div style={{ overflowX: 'auto' }}>
      <DownloadCSVHeaderRow
        data={onDownloadClick}
        loading={downloading}
        filename={'data-log.csv'}
        showFilter={true}
      />
      {datalogCol?.length && (
        <MaterialTable
          title="Data log"
          columns={datalogCol}
          options={{
            debounceInterval: 700,
            padding: 'dense',
            filtering: false,
            pageSize: 30,
            search: false,
          }}
          data={(query) =>
            new Promise((resolve, reject) => {
              const page = query.page;
              const limit = 30;

              getDeviceDatalogApiCall(page, limit).then((res) => {
                resolve({
                  data: res.data,
                  page: query.page,
                  totalCount: res.data[0]['TotalCount'],
                });
              });
            })
          }
        />
      )}
    </div>
  );
}
