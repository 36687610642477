/* eslint-disable react/jsx-key */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Table } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { getDevices } from '../../actions/devices';
import DownloadCSVHeaderRow from '../Utils/DownloadCSVHeaderRow';
import { convertColumnToRealName } from '../../lib/convertColumnsToRealLanguage';

export default function ViewDevices() {
  const devices = useSelector((state) => state.devices.devices) || [];
  const objectKeys = devices.length && Object.keys(devices[0]).filter((key) => key != 'GeoCoords');
  devices.length && objectKeys.push('Logs', 'Events', 'Load History');
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDevices());
  }, [dispatch]);

  const headers = objectKeys ? objectKeys.map((key) => ({ label: key, key })) : [];

  return (
    <div>
      <DownloadCSVHeaderRow
        data={() => {
          return devices.map((d) => {
            delete d['GeoCoords'];
            return d;
          });
        }}
        filename={'devices.csv'}
      />
      <Table responsive="md">
        <thead>
          <tr>
            {objectKeys &&
              objectKeys.map((key) => {
                return <th>{convertColumnToRealName(key)}</th>;
              })}
          </tr>
        </thead>
        <tbody>
          {devices.map((data) => (
            <tr key={data.IndexID}>
              {objectKeys &&
                objectKeys.map((key) => {
                  if (key === objectKeys[objectKeys.length - 3]) {
                    const routePath = '/devices-data-log?id=' + data[objectKeys[1]];
                    return (
                      <td>
                        <NavLink to={routePath}>
                          <Button size="sm" variant="warning" className="device-row-button">
                            Logs
                          </Button>
                        </NavLink>
                      </td>
                    );
                  } else if (key === objectKeys[objectKeys.length - 2]) {
                    const routePath = '/device-events?id=' + data[objectKeys[1]];
                    return (
                      <td>
                        <NavLink to={routePath}>
                          <Button size="sm" variant="info" className="device-row-button">
                            Events
                          </Button>
                        </NavLink>
                      </td>
                    );
                  } else if (key === objectKeys[objectKeys.length - 1]) {
                    const routePath = '/device-load-history?id=' + data[objectKeys[1]];
                    return (
                      <td>
                        <NavLink to={routePath}>
                          <Button size="sm" variant="primary" className="device-row-button">
                            Loads
                          </Button>
                        </NavLink>
                      </td>
                    );
                  }
                  return <td>{data[key]}</td>;
                })}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
