import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { ToastContainer, toast } from 'react-toastify';
import { HashLoader } from 'react-spinners';
import { Link } from 'react-router-dom';

import logo from './logo.png';
import './style.css';
import { forgotPasswordApiCall } from '../../services/users';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  function ValidateEmail(email) {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
      return true;
    }
    return false;
  }

  function handleSubmit(event) {
    setLoading(true);
    forgotPasswordApiCall(email)
      .then((apiRes) => {
        if (apiRes.data.success) {
          toast.success('A password reset link has been sent to your email.', { autoClose: 30000 });
        } else {
          toast.error('Something went wrong, please try again later.');
        }
        setLoading(false);
        setEmail('');
      })
      .catch((error) => {
        if (error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error('Something went wrong, please try again later.');
        }
        setLoading(false);
      });
  }

  return (
    <div className="limiter">
      <ToastContainer position="top-right" />
      <div className="container-login100">
        <div className="wrap-login100">
          <div>
            <img src={logo} alt="Logo" className="Center Logo" />
            <h5 className="Center Margin mb-4">Forgot Password?</h5>
            <div className="login-fields-container">
              <TextField
                id="outlined-password-input"
                label="Email Address"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="login-fields"
              />
            </div>
            <Button
              size="medium"
              type="submit"
              variant="contained"
              className="full-width"
              disabled={!ValidateEmail(email) || loading}
              onClick={handleSubmit}
            >
              {loading && <HashLoader size={30} color="#1b6bae" speedMultiplier={0.8} />}
              {!loading && 'Reset Password'}
            </Button>
            <div className="mt-2 auth-nav-link">
              <Link to="/auth">Return to Sign In</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
